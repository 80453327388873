import React, {FormEvent, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { images } from '../../assets/image';
import { routes } from '../../routes/routesConstants';
import { render } from 'react-dom'
import { Layout } from '../../components';
import './profile.scss';
import {userProfileService} from "../../services/profile.service";
import {authService} from "../../services/auth.service";
import {generateKeyPair} from "crypto";

interface ProfileData {
    full_name: string,
    gender: string,
    age: any,
    country : any,
    state : any,
    city : string,
    position : string,
    image : string,
    avatar : any,
}
export const EditProfilePage = () => {

    const [profileData, setProfileData] = useState <ProfileData>({
        full_name: authService.userInfo().name,
        gender: authService.userInfo().gender,
        age: authService.userInfo().age,
        country : authService.userInfo().country,
        state : authService.userInfo().state,
        city : authService.userInfo().city,
        position : authService.userInfo().position,
        image : authService.userInfo().avatar,
        avatar : null
    });

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    let history = useHistory();

    useEffect(() => {
        userProfileService.countries({getCountryList: 1,withUserStates : authService.userId()}).then(({data} : any) => {
            if (data.status_code == 200){
                setCountries(data.message)
                setStates(data.states)
                setCities(data.cities)
            }
        })
    }, []);

    let getStats = (countryId : number) => {
        userProfileService.countries({country: countryId}).then(({data} : any) => {
            if (data.status_code == 200){
                setStates(data.message)
                setProfileData({...profileData,country:countryId,city:''});
            }
        })
    }

    let getCities = (stateId : number) => {
        setProfileData({...profileData,state:stateId});
        userProfileService.countries({state: stateId}).then(({data} : any) => {
            if (data.status_code == 200){
                setCities(data.message)
            }
        })
    }

    let selectAvatar = (e : any) => {
        let file = e.target.files[0];
        console.log(URL.createObjectURL(file))
        setProfileData({...profileData,image:URL.createObjectURL(file),avatar:file})
    }

    let updateProfile = (e : FormEvent) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('avatar',profileData.avatar);
        formData.append('full_name',profileData.full_name);
        formData.append('gender',profileData.gender);
        formData.append('age',profileData.age);
        formData.append('country',profileData.country);
        formData.append('state',profileData.state);
        formData.append('city',profileData.city);
        formData.append('position',profileData.position);

        userProfileService.update(formData).then(({data} : any) => {
            if (data.status_code == 200){
                authService.setUpdatedUserInfo(data.user)
                history.push(routes.profile)
            }
        });
    }


    return (
        <Layout>
            <div className="rt-content rt-editprofile">
                <div className="rt-pageheading">
                    <div className="rt-title">
                        <Link to="/profile" className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                        <h2>EDIT PROFILE</h2>
                    </div>
                </div>
                <div className="rt-editprofilearea">
                    <form className="rt-formtheme rt-editprofileform" onSubmit={updateProfile}>
                        <fieldset>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <figure className="rt-roundimage rt-profileimage">
                                        <img src={profileData.image} alt="" />
                                        <label className="rt-uploadimage">
                                            <input type="file" className="form-control" value={""} onChange={selectAvatar}/>
                                            <i className="icon-pencil"></i>
                                        </label>
                                    </figure>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="rt-fieldholder">
                                            <input type="text" name="" className="form-control" id="name-field" placeholder="FULL NAME" value={profileData.full_name} onChange={e => setProfileData({...profileData,full_name:e.target.value})} />
                                            <label htmlFor="name-field">FULL NAME</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="rt-fieldholder">
                                            <div className="rt-select">
                                                <select className="form-control" id="gender-field" value={profileData.gender} onChange={e => setProfileData({...profileData,gender:e.target.value})}>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </div>
                                            <label htmlFor="gender-field">gender</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="rt-fieldholder">
                                            <input type="number" value={profileData.age} name="" className="form-control" id="age-field" placeholder="age" onChange={e => setProfileData({...profileData,age:Number(e.target.value)})}/>
                                            <label htmlFor="age-field">age</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="rt-fieldholder">
                                            <div className="rt-select">
                                                <select className="form-control" id="country-field" value={profileData.country} onChange={e => getStats(Number(e.target.value))}>
                                                    <option>Please Select Country</option>
                                                    {countries.map((country : any)=> {
                                                        return (<option value={country.id}>{country.name}</option>)
                                                    })}
                                                </select>
                                            </div>
                                            <label htmlFor="country-field">country</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="rt-fieldholder">
                                            <div className="rt-select">
                                                <select className="form-control" id="state-field" value={profileData.state} onChange={e => getCities(Number(e.target.value))}>
                                                    <option>Please Select State</option>
                                                    {states.map((state : any)=> {
                                                        return (<option value={state.id}>{state.name}</option>)
                                                    })}                                                </select>
                                            </div>
                                            <label htmlFor="state-field">state</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="rt-fieldholder">
                                            <div className="rt-select">
                                                <select className="form-control" id="city-field" value={profileData.city} onChange={e => setProfileData({...profileData,city:e.target.value})}>
                                                    <option>Please Select City</option>
                                                    {cities.map((city : any)=> {
                                                        return (<option value={city.name} selected={city.id == profileData.city ? true : false}>{city.name}</option>)
                                                    })}                                                </select>
                                            </div>
                                            <label htmlFor="city-field">city</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="rt-fieldholder">
                                            <div className="rt-select">
                                                <select className="form-control" id="position-field" value={profileData.position} onChange={e => setProfileData({...profileData,position: e.target.value})} >
                                                    <option value="DEFENDER">DEFENDER</option>
                                                    <option value="Attacking"> Attacking</option>
                                                    <option value="Mid-Fielder">Mid-Fielder</option>
                                                </select>
                                            </div>
                                            <label htmlFor="position-field">POSITION</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                                    <div className="form-group">
                                        <button type="submit" className="rt-btn rt-black-btn">UPDATE</button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </Layout>
    );
};