import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../assets/image';
import { Layout } from '../../components';
import { Nav, Tab } from "react-bootstrap";
import { CourseCardComponent } from '../../components/course-card';
import Table from 'react-bootstrap/Table'
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import './leaderboard.scss';
// @ts-ignore
import ReactPaginate from 'react-paginate';
import {leaderboardService} from "../../services/leaderboard.service";
import LeaderboardTop3Loader from "./leaderboard-top3-loader";
import LeaderboardLoader from "./leaderboard-loader";

interface IProps {
    data: any;
}

export const LeaderBoard: React.FC<IProps> = (props) => {

    const [allTime, setAllTime] = useState([]);
    const [monthly, setMonthly] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [totalPageMonthly, setTotalPageMonthly] = useState(0);
    const [allTimeInit, setAllTimeInit] = useState(false);
    const [topThree, setTopThree] = useState([]);
    const [topThreeAll, setTopThreeAll] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        leaderboardService.monthly().then(({data}: any) => {
            setMonthly(data.data)
            setTopThree(data.data.slice(0, 3))
            setTotalPageMonthly(data.totalPages)
            setLoading(false)
        })
    }, []);

    let getAllTime = (e: any) => {
        let page = e.selected ?? 0;
        leaderboardService.allTime(page+1).then(({data}: any) => {
            setAllTime(data.data)
            if (topThreeAll.length == 0){
                setTopThreeAll(data.data.slice(0, 3))
            }
            setTotalPage(data.totalPages)
            setLoading(false)
        })
    }
    let getMonthly = (e: any) => {
        let page = e.selected ?? 0;
        leaderboardService.monthly(page+1).then(({data}: any) => {
            setMonthly(data.data)
            setTotalPageMonthly(data.totalPages)
        })
    }

    let allTimeDataInit = (e:any) => {
        if (!allTimeInit) {
            setAllTimeInit(true);
            setLoading(true)
            getAllTime(e)
        }
    }

    return (
        <Layout title="Leaderboard">
            <div className="rt-content rt-leaderboard">
                <Tab.Container id="left-tabs-example" defaultActiveKey='tab1'>
                    <div className="rt-pageheading">
                        <div className="rt-title">
                            <h2>TEAM LEADERBOARD</h2>
                        </div>
                        <Nav className="rt-navtabs">
                            <Nav.Item>
                                <Nav.Link eventKey="tab1">MONTHLY</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab2" onClick={(e:any) => allTimeDataInit(e)}>ALL TIME</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="rt-rt-leaderboardarea">
                        <Tab.Content className="rt-tabcontent">
                            <Tab.Pane eventKey="tab1">
                                <div className="rt-teamboard">
                                    {loading ? <LeaderboardTop3Loader/> :
                                        (topThree.map((d: any, key: any) => {
                                            return (<div className="rt-themecardbox">
                                                <figure className="rt-roundimage">
                                                    <img src={d.avatar} alt=""/>
                                                </figure>
                                                <div className="rt-themecontent">
                                                    <span>{key + 1}{key + 1 == 1 ? 'st' : (key + 1 == 2 ? 'nd' : 'rd')}</span>
                                                    <h4>{d.name}</h4>
                                                    <em>{d.completed_sessions}</em>
                                                </div>
                                            </div>)
                                        }))}
                                </div>

                                <Table responsive className="rt-table rt-teamtable">
                                    <thead>
                                    <tr>
                                        <th>Trophies</th>
                                        <th>TEAM MEMBERS</th>
                                        <th>LEVEL</th>
                                        <th>POINTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loading ? <LeaderboardLoader/>: monthly.map((d : any )=> {
                                        return ( <tr>
                                            <td><Link to={"/member-profile/"+d.id}>
                                                <figure className="rt-roundimage">
                                                    <img src={d.trophy} alt="" />
                                                </figure>
                                            </Link></td>
                                            <td><Link to={"/member-profile/"+d.id}>{d.name}</Link></td>
                                            <td><Link to={"/member-profile/"+d.id}>{d.level}</Link></td>
                                            <td><Link to={"/member-profile/"+d.id}>{d.completed_sessions}</Link></td>
                                        </tr>)
                                    })}

                                    </tbody>
                                </Table>

                                {totalPageMonthly > 1 ?
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'page-item'}
                                        nextClassName={'rt-next page-item'}
                                        previousClassName={'rt-prev page-item'}
                                        pageCount={totalPageMonthly}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        nextLinkClassName={'page-link'}
                                        previousLinkClassName={'page-link'}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={(e:any)=> getMonthly(e)}
                                        containerClassName={'rt-pagination pagination'}
                                        activeClassName={'active'}
                                    /> : ''}
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab2">
                                <div className="rt-teamboard">
                                    {loading ? <LeaderboardTop3Loader /> : topThreeAll.map((d:any,key:any) => {
                                        return (<div className="rt-themecardbox">
                                            <figure className="rt-roundimage">
                                                <img src={d.avatar} alt="" />
                                            </figure>
                                            <div className="rt-themecontent">
                                                <span>{key+1}{key+1 == 1?'st' : (key+1 == 2 ? 'nd' : 'rd')}</span>
                                                <h4>{d.name}</h4>
                                                <em>{d.completed_sessions}</em>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                                <Table responsive className="rt-table rt-teamtable">
                                    <thead>
                                    <tr>
                                        <th>Trophies</th>
                                        <th>TEAM MEMBERS</th>
                                        <th>LEVEL</th>
                                        <th>POINTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loading ? <LeaderboardLoader/>:allTime.map((d : any )=> {
                                        return ( <tr>
                                            <td><Link to={"/member-profile/"+d.id}>
                                                <figure className="rt-roundimage">
                                                    <img src={d.trophy} alt="" />
                                                </figure>
                                            </Link></td>
                                            <td><Link to={"/member-profile/"+d.id}>{d.name}</Link></td>
                                            <td><Link to={"/member-profile/"+d.id}>{d.level}</Link></td>
                                            <td><Link to={"/member-profile/"+d.id}>{d.completed_sessions}</Link></td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </Table>

                                {totalPage > 1 ?
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'page-item'}
                                        nextClassName={'rt-next page-item'}
                                        previousClassName={'rt-prev page-item'}
                                        pageCount={totalPage}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        nextLinkClassName={'page-link'}
                                        previousLinkClassName={'page-link'}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={(e:any)=> getAllTime(e)}
                                        containerClassName={'rt-pagination pagination'}
                                        activeClassName={'active'}
                                    /> : ''}
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </Layout>
    );
};