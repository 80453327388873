import React, {useEffect, useState} from "react";
import { Layout } from "../../components";
import { images } from "../../assets/image";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"
import { SessionCardComponent } from "../../components";
import { CategoryCardComponent } from "../../components";
import { SelectedVideoCardComponent } from "../../components";
import { AddSessionData } from "./addsession-data";
import { data } from "./data";
import { VideoCardData } from "./videocard-data";
import "./organize-training.scss";
import { Link } from "react-router-dom";
import { DeleteModal } from "../../components/modals";
import {organizeTrainingService} from "../../services/organize-training.service";
import {sessionService} from "../../services/sessions.service";
import AddedSessionLoader from "./added-sessions-loader";
import SelectCategoryLoader from "./select-category-loader";
import SelectSessionsLoader from "./select-sessions-loader";

export const OrganizeTraining = (props: any) => {
    const [modalShow, setModalShow] = useState(false);

    const [videoCart, setVideoCart] = useState(false);
    const [sessions, setSessions] = useState(true);
    const [categories, setCategories] = useState([]);
    const [categorySessions, setCategorySessions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({
        name:''
    });

    const [loading, setLoading] = useState(true);

    const [calendarData, setCalendarData] = useState([]);
    const [addedSessions, setAddedSessions] = useState([]);
    const [deleteSessions, setDeleteSessions] = useState([]);
    const [deleteSessionDate, setDeleteSessionDate] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    let selectedSessions : any = [];

    useEffect(() => {
        organizeTrainingService.index().then(({data})=>{
            if (data.status_code == 200){
                setCalendarData(data.data.session_for_calendar)
                setAddedSessions(data.data.added_sessions)
                setCategories(data.data.session_categories)
                setLoading(false);
            }
        })

        const yourDate = new Date()
        let date = yourDate.toISOString().split('T')[0]

        document.querySelectorAll("td[data-date='" + date + "']").forEach((e:any) => {
            e.classList.add('day-highlight')
        })
    }, []);

    let addSession = () => {
        setSessions(false);
    }


    let selectSessions  = (e:any) => {
        var index = selectedSessions.indexOf(e.target.value);
        if (index !== -1) {
            selectedSessions.splice(index, 1);
        }else{
            selectedSessions.push(e.target.value)
        }
    }

    let postAddSessionForm = async (e:any) => {
        e.preventDefault();
        setVideoCart(false);
        setSessions(true);
        setLoading(true);
        let date = selectedDate;
        if (selectedDate == '') {
            const yourDate = new Date()
            date = yourDate.toISOString().split('T')[0]
        }
        let sessions = selectedSessions.toString();
        await organizeTrainingService.addSession({date:date,sessionId:sessions}).then(({data} : any) => {

            organizeTrainingService.index().then(({data})=>{
                if (data.status_code == 200){
                    setCalendarData(data.data.session_for_calendar)
                    setAddedSessions(data.data.added_sessions)
                    setCategories(data.data.session_categories)
                    setLoading(false)
                }
            })
        })

    }

    let getCategorySessions = (categoryId : number) => {
        setLoading(true)
        setVideoCart(true);
        setSessions(false);
        sessionService.categorySessions(categoryId).then(({data})=>{
            if (data.status_code == 200){
                setCategorySessions(data.data.session)
                setSelectedCategory(data.data.category)
                setLoading(false)
            }
        })
    }

    let getSessionByDate = (event:any) => {
        setLoading(true)
        setSessions(true);
        setVideoCart(false)
        console.log(event)
        let newSelectedDate = ''
        document.querySelectorAll("td.fc-daygrid-day").forEach((e:any) => {
            e.classList.remove('day-highlight')
        })
        if (selectedDate == '' || selectedDate != event.dateStr){
            document.querySelectorAll("td[data-date='" + event.dateStr + "']").forEach((e:any) => {
                e.classList.add('day-highlight')
            })
            newSelectedDate = event.dateStr;
        }
        setSelectedDate(newSelectedDate)

        organizeTrainingService.index(newSelectedDate).then(({data})=>{
            if (data.status_code == 200){

                setAddedSessions(data.data.added_sessions)
                setLoading(false)
            }
        })
    }



    const renderComponents = () => {

        console.log(videoCart,sessions,categories)

        if (videoCart) {
            return (
                <div className="rt-sessionslistarea rt-sessionsidebar">
                    <div className="rt-title">
                        <h2>
                            <button
                                onClick={() => {
                                    setVideoCart(false);
                                    setSessions(false);
                                }}
                                type="button"
                                className="rt-backbtn"
                            >
                                <i className="icon-arrow-left"></i>
                            </button>{" "}
                            {loading ? 'Loading...' : selectedCategory.name}
                        </h2>
                    </div>
                    <div className="rt-sessionslistholder rt-selectvideocard">
                        <div className="rt-sessionslist">
                            {loading ? <SelectSessionsLoader /> : (
                            <form className="rt-themeform" onSubmit={e => postAddSessionForm(e)}>
                                {categorySessions.map((d: any) => (
                                    //    <SelectedVideoCardComponent
                                    //        data={d}
                                    //    />
                                    <div className="rt-themecardbox">
                                        <label className="rt-videoimg rt-customcheckbox">
                                            <img src={d.full_image} alt="" />
                                            <input type="checkbox" value={d.id} onChange={(e:any) => selectSessions(e)}/>
                                            <span className="rt-checkmark"></span>
                                        </label>
                                        <div className="rt-themecontent">
                                            <h4>{d.name}</h4>
                                            <p>{d.description}</p>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    type="submit"
                                    className="rt-submitbtn">
                                    <i className="icon-tick"></i>
                                </button>
                            </form>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else if (sessions) {
            return (
                <div className="rt-sessionslistarea rt-sessionsidebar">
                    <div className="rt-title">
                        <h2>ADDED SESSIONS <button onClick={addSession}>+</button></h2>
                        <span>{selectedDate}</span>
                    </div>
                    <div className="rt-sessionslistholder rt-addsessionlist">
                        {loading? <AddedSessionLoader /> : (
                        <div className="rt-sessionslist">
                            {addedSessions.map((d: any) => (
                                // <SessionCardComponent
                                //     data={d}
                                // />
                                <div className="rt-themecardbox">
                                      <span className="rt-rectangelbox">
                                        <em>{d.date}</em> {d.day}
                                      </span>
                                    <div className="rt-themecontent">
                                        <ol>
                                            {d.sessions.map((session: any) => (
                                                <Link to={'session/'+session.id+'/detail'}><li>{session.name}</li></Link>
                                            ))}
                                        </ol>
                                    </div>
                                    <button
                                        type="button"
                                        className="rt-deletbtn"
                                        onClick={() => {
                                            setDeleteSessions(d.sessions);
                                            setDeleteSessionDate(d.full_date)
                                            setModalShow(true)
                                        }}
                                    >
                                        <i className="icon-delet"></i>
                                    </button>
                                    {modalShow && (
                                        <DeleteModal
                                            show={modalShow}
                                            deleteSessions={deleteSessions}
                                            deleteSessionDate={deleteSessionDate}
                                            setAddedSessions={setAddedSessions}
                                            setCalendarData={setCalendarData}
                                            onHide={() => setModalShow(false)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rt-sessionslistarea rt-sessionsidebar">
                    <div className="rt-title">
                        <h2>SELECT CATEGORY</h2>
                        <span>{selectedDate}</span>
                    </div>
                    <div className="rt-sessionslistholder rt-selectcategory">
                        {loading? <SelectCategoryLoader /> : (
                        <div className="rt-sessionslist">
                            {categories.map((d: any) => (
                                <div
                                    className="rt-category"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        getCategorySessions(d.id);

                                    }}
                                >
                                    <img src={d.full_image} alt="" />
                                    <div className="rt-category-content">
                                        <Link to={'/'} onClick={e => e.preventDefault()}>
                                            <h3>
                                                {d.name}
                                                <span>{d.name1}</span>
                                            </h3>
                                            <span className="rt-sessioncounter">
                                                <i className="icon-tick"></i>
                                                {d.counter}
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                        )}
                    </div>
                </div>
            );
        }
    };
    return (
        <Layout title="ORGANIZE MY TRAINING">
            <div className="rt-content rt-organize-training">
                <div className="rt-calendararea">
                    <div className="rt-schedule">
                        <div className="rt-title">
                            <h2>Schedule</h2>
                        </div>
                        <FullCalendar
                            plugins={[dayGridPlugin,interactionPlugin]}
                            initialView="dayGridMonth"
                            events={calendarData}
                            dateClick={(info:any) => getSessionByDate(info)}
                        />
                    </div>
                </div>
                {renderComponents()}
            </div>
        </Layout>
    );
};
