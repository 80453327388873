import React from 'react';

var month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const DashboardSessionComponent = ({session} : any) => {

    console.log(session)
    return (
        <div className="rt-themecardbox">
                                <span className="rt-rectangelbox">
                                    <em>{(new Date(session.pivot.date).getDate())}</em> {month[(new Date(session.pivot.date)).getMonth()]}
                                </span>
            <div className="rt-themecontent">
                <h4>{session.name}</h4>
                <span>{session.category.name}</span>
            </div>
        </div>

    );
};

export default DashboardSessionComponent;
