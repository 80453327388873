import React, { useEffect, useState } from "react";
import { images } from "../../assets/image";
import { Link } from "react-router-dom";
import './category-card.scss'
import {ASSET_ENDPOINT, ASSET_ENDPOINT_LIVE} from "../../environment";
import {routes} from "../../routes/routesConstants";

interface IProps {
    showCounter?: any;
    data: any,
    link ? : any
}

export const CategoryCardComponent: React.FC<IProps> = ({data,showCounter = false,link = false}) => {

    console.log(link)
    return (

        <div className="rt-category">
            <img src={data.full_image} alt="" />
            <div className="rt-category-content">
                <Link to={link ? data.link : '/category/'+data.id+'/sessions' }>
                    <h3>{data.name}<span>{data.name1}</span></h3>
                    {showCounter && (<span className="rt-sessioncounter"><i className="icon-tick"></i>{data.counter}</span>)}
                </Link>
            </div>
        </div>

    )
}