import React, {useState} from "react";
import { Modal } from 'react-bootstrap';
import {organizeTrainingService} from "../../services/organize-training.service";


export const DeleteModal = (props:any) => {

    const [show, setShow] = useState(props.show);
    const handleClose = () => setShow(false);

    let selectedSessions : any = [];

    let selectSessions  = (e:any) => {
        var index = selectedSessions.indexOf(e.target.value);
        if (index !== -1) {
            selectedSessions.splice(index, 1);
        }else{
            selectedSessions.push(e.target.value)
        }
    }

    let deleteSelectedSessions = () => {

        if (selectedSessions.length == 0){
            alert('Please choose at least one session to delete');
            return 0;
        }

        organizeTrainingService.deleteSession({date:props.deleteSessionDate,id:selectedSessions.toString()}).then(({data} : any) => {
            if (data.status_code == 200){
                organizeTrainingService.index().then(({data})=>{
                    if (data.status_code == 200){
                        props.onHide()
                        props.setCalendarData(data.data.session_for_calendar)
                        props.setAddedSessions(data.data.added_sessions)
                    }
                })
            }
        })
    }


    return(
        <Modal show={show} className="rt-thememodal rt-deletemodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-deletemodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-deletecontent">
                        <div className="rt-modaltitle">
                            <h3>WANT TO DELETE SESSION?</h3>
                            <div className="rt-description">
                                <p>Are you sure want to delete.</p>
                            </div>
                            {props.deleteSessions.map((session:any) => {
                                return (
                                    <>
                                        <input type="checkbox" value={session.id} onChange={(e:any) => selectSessions(e)}/>
                                        <li>{session.name}</li>
                                    </>

                                )
                            })}

                        </div>
                        <div className="rt-btns">
                            <button type="button" className="rt-btn rt-border-btn" onClick={props.onHide}>CANCEL</button>
                            <button type="button" className="rt-btn" onClick={deleteSelectedSessions}>DELETE</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
