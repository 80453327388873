import Reactf,{ useState, useEffect } from 'react'
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { images } from '../../assets/image'
import { routes } from '../../routes/routesConstants'
import './sidebar.scss';
import {authService} from "../../services/auth.service";

interface IProps {
    toggleClassHandler?: Function;
}

export const SidebarComponent: React.FC<IProps> = (props) =>  {

    const toggle = () => {
        if (props.toggleClassHandler) {
            props.toggleClassHandler();
        }
    };

    return (
        <aside className="rt-sidebarmenu">
            <div className="rt-logoarea">
                <strong className="rt-logo">
                    <Link to="/">
                        <img src={images.logo} className="rt-logoweb" alt="" />
                        <img src={images.signuplogo} className="rt-logomobile" alt="" />
                    </Link>
                </strong>
                <button type="button" className="rt-togglemenubtn" onClick={() => toggle()}>
                    <i className="icon-cross"></i>
                </button>
            </div>
            <div className="at-navigation">
                <ul className="rt-navlist">
                    <li>
                        <Link to="/dashboard">
                            <i className="icon-dashboard"></i>
                            <span>DASHBOARD</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/leaderboard">
                            <i className="icon-leaderboard"></i>
                            <span>LEADERBOARD</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/progress">
                            <i className="icon-progress"></i>
                            <span>PROGRESS</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/challenges">
                            <i className="icon-leaderboard"></i>
                            <span>Challenges</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/teammates">
                            <i className="icon-teammates"></i>
                            <span>TEAMMATES</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="rt-logoutarea">
                <button type="button" className="rt-logoutbtn" onClick={authService.signout}>
                    <span>LOGOUT</span> <i className="icon-logout"></i>
                </button>
            </div>
        </aside>
    )
}