import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { images } from '../../assets/image';
import { routes } from '../../routes/routesConstants'
import { FaBars } from "react-icons/fa";
import './header.scss';
import {authService} from "../../services/auth.service";


interface IProps {
    toggleClassHandler?: Function;
    title: any;
}

export const HeaderComponent: React.FC<IProps> = (props) => {
    const toggle = () => {
        if (props.toggleClassHandler) {
            props.toggleClassHandler();
        }
    };

    return (
        <header className="rt-header">
            <button type="button" className="rt-togglemenubtn" onClick={() => toggle()}>
                <FaBars />
            </button>
            <h1>{props.title}</h1>
            <div className="rt-addnav">
                <button className="rt-changemoodbtn rt-roundicon">
                    <i className="icon-sun"></i>
                </button>
                <Link to="/profile" className="rt-topbarprofile">
                    <div className="rt-themecardbox">
                        <figure className="rt-roundimage">
                            <img src={authService.userInfo().avatar} alt="" />
                        </figure>
                        <div className="rt-themecontent">
                            <h4>{authService.userInfo().name}</h4>
                            <span>{authService.userInfo().position}</span>
                        </div>
                    </div>
                </Link>
            </div>
        </header>
    )
}
