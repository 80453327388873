import React, {Component, FormEvent, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstants'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import './signup.scss';
import {PAYMENT_METHOD} from "../../../environment";
import {authService} from "../../../services/auth.service";

interface UserData {
    firstName:string,
    email:string,
    user_name:string,
    password:string,
    password_confirmation:string,
    phone:string,
    gender:string,
    age:number,
    position:string
}

export const SignupUser = () => {
    const [membershipDuration, setMembershipDuration] = useState('monthly');
    const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.chargebee);
    const [userData, setUserData] = useState<UserData>({
        firstName:'',
        email:'',
        password:'',
        password_confirmation:'',
        phone:'',
        user_name:'',
        gender:'',
        age:0,
        position:''
    });

    let history = useHistory();

    let signUp = (e : FormEvent) : void => {
        e.preventDefault();
        console.log('test')
        authService.signup(userData).then(({data}) => {
            if (data.status_code == 200){
                authService.authenticate(data.data)
                history.push(routes.dashboard)
            }
        })
    }
    console.log(userData)
    return (
        <div className="rt-signupholder h-100">
            <div className="rt-heading">
                <h2>MEMBERSHIP</h2>
            </div>
            <div className="rt-membershipplan">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav className="rt-navtabs">
                        <Nav.Item>
                            <Nav.Link eventKey="first" onClick={() => setMembershipDuration('monthly')}>MONTHLY</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second" onClick={() => setMembershipDuration('yearly')}>YEARLY(SAVE 17.9%)</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div className="rt-tabcontentarea">
                                <div className="rt-themecardbox">
                                    <div className="rt-roundicon">
                                        <i className="icon-dollar"></i>
                                    </div>
                                    <div className="rt-themecontent">
                                        <span><strong>$19.99 </strong> Monthly Validity</span>
                                        <h4>MST Membership Plan</h4>
                                        <em>Unlimited Programs, Sessions & Drills</em>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <div className="rt-tabcontentarea">
                                <div className="rt-themecardbox">
                                    <div className="rt-roundicon">
                                        <i className="icon-dollar"></i>
                                    </div>
                                    <div className="rt-themecontent">
                                        <span><strong>$197 </strong> Yearly Validity</span>
                                        <h4>MST Yearly</h4>
                                        <em>Unlimited Programs, Sessions & Drills</em>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            <div className="rt-formbox">
                <form className="rt-formtheme rt-forgotpasswordform" onSubmit={signUp}>
                    <fieldset>
                        {/* <div className="rt-logobox">
                            <strong className="rt-signuplogo"><Link to={routes.login}><img src={images.signuplogo} alt="" /></Link></strong>
                        </div> */}
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group text-center">
                                    <h3>ACCOUNT INFORMATION</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" required id="name-field" placeholder="FIRST NAME" value={userData.firstName} onChange={e => setUserData({...userData,firstName:e.target.value})} />
                                        <label htmlFor="name-field">FULL NAME</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="email" name="" className="form-control" required id="email-field" placeholder="EMAIL" value={userData.email} onChange={e => setUserData({...userData,email:e.target.value})}/>
                                        <label htmlFor="email-field">EMAIL</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" required id="name-field" placeholder="USERNAME" value={userData.user_name} onChange={e => setUserData({...userData,user_name:e.target.value})}/>
                                        <label htmlFor="name-field">USERNAME</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="number" name="" className="form-control" required id="number-field" placeholder="PHONE" value={userData.phone} onChange={e => setUserData({...userData,phone:e.target.value})}/>
                                        <label htmlFor="number-field">PHONE</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="password" name="" className="form-control" required id="password-field" placeholder="PASSWORD" value={userData.password} onChange={e => setUserData({...userData,password:e.target.value})}/>
                                        <label htmlFor="password-field">PASSWORD</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="password" name="" className="form-control" required id="password-field" placeholder="CONFIRM PASSWORD" value={userData.password_confirmation} onChange={e => setUserData({...userData,password_confirmation:e.target.value})}/>
                                        <label htmlFor="password-field">CONFIRM PASSWORD</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <div className="rt-select">
                                            <select name="" className="form-control" required id="gender-field" value={userData.gender} onChange={e => setUserData({...userData,gender:e.target.value})}>
                                                <option value="">Please Select</option>
                                                <option value="male">MALE</option>
                                                <option value="female">FEMALE</option>
                                            </select>
                                        </div>
                                        <label htmlFor="gender-field">GENDER</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="number" name="" className="form-control" required id="age-field" placeholder="AGE" value={userData.age} onChange={e => setUserData({...userData,age:Number(e.target.value)})}/>
                                        <label htmlFor="age-field">AGE</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" required id="position-field" placeholder="POSITION" value={userData.position} onChange={e => setUserData({...userData,position:e.target.value})}/>
                                        <label htmlFor="position-field">POSITION</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group text-center mt-30">
                                    <h3>SELECT PAYMENT METHOD</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-fieldholder rt-customradiobox">
                                        <div className="rt-customradio-area">
                                            <label className="rt-customradio">
                                                <input type="radio" name="paymentMethod" value={PAYMENT_METHOD.chargebee} onChange={e => setPaymentMethod(e.target.value)}/>
                                                <span className="rt-checkmark"></span>
                                            </label>
                                            <span> CREDIT CARD</span>
                                        </div>
                                        <figure className="rt-cardsimage m-0">
                                            <img src={images.cards} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-fieldholder rt-customradiobox">
                                        <div className="rt-customradio-area">
                                            <label className="rt-customradio">
                                                <input type="radio" name="paymentMethod" value={PAYMENT_METHOD.paypal} onChange={e => setPaymentMethod(e.target.value)}/>
                                                <span className="rt-checkmark"></span>
                                            </label>
                                            <span>SIGN UP VIA PAYPAL</span>
                                        </div>
                                        <figure className="rt-cardsimage m-0">
                                            <img src={images.paymenticon} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-customcheckbox-area">
                                        <label className="rt-customcheckbox">
                                            <input type="checkbox" />
                                            <span className="rt-checkmark"></span>
                                        </label>
                                        <span>I have read and agree to the <a href="javascript:void(0);">Terms</a> of Use and <a href="javascript:void(0);">Privacy Policy</a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group m-0">
                                    <button className="rt-btn rt-btn-lg p-0" type="submit">SIGN UP</button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};