import { HttpService } from "./base.service";
import APIEndpoints from "./APIEndpoints";
import {authService} from "./auth.service";

class UserProfileService extends HttpService {
    private readonly prefix: string = '';
    constructor() {
        super();
        HttpService.setToken(authService.authToken())
    }
    /**
     * Dashboard Data Service
     */
    profileInfo = (id:number) => this.get(`${this.prefix}user/${id}/profile`);

    memberProfile = (id:number) => this.get(`${this.prefix}member/${id}/profile`);

    countries = (data: any) => this.get(`${this.prefix}${APIEndpoints.COUNTRIES}`,data);

    findMembers = (page = 1,search = '') => this.get(`${this.prefix}${APIEndpoints.FIND_MEMBERS}?page=${page}&search=${search}`);

    follows = (page = 1,search = '') => this.get(`${this.prefix}${APIEndpoints.FOLLOWS}?page=${page}&search=${search}`);

    update = (data : any) => this.post(`${this.prefix}${APIEndpoints.UPDATE_PROFILE}`,data)

    follow = (id : any) => this.post(`${this.prefix}${APIEndpoints.FOLLOW}`, {user_id:id})

    unfollow = (id : any) => this.post(`${this.prefix}${APIEndpoints.UN_FOLLOW}`, {user_id:id})

    updatePassword = (data : any) => this.post(`${this.prefix}${APIEndpoints.UPDATE_PASSWORD}`,data)

}

export const userProfileService = new UserProfileService();
