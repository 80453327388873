import React, {useEffect, useState} from "react";
import FigureCaption from "react-bootstrap/esm/FigureCaption";
import { Link } from "react-router-dom";
import { images } from "../../assets/image";
import { Layout } from "../../components";
import { data } from "./data";
import "./teammates.scss";
import {leaderboardService} from "../../services/leaderboard.service";
import {ASSET_ENDPOINT, ASSET_ENDPOINT_LIVE} from "../../environment";
// @ts-ignore
import ReactPaginate from 'react-paginate';
import TeamMatesLoader from "./team-mates-loader";

interface IProps {
  data: any;
}

export const TeammatesPage: React.FC<IProps> = (props: any) => {

    const [socialUpdates, setSocialUpdates] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        leaderboardService.teamMates().then(({data}:any) => {
            if (data.status_code == 200){
                setSocialUpdates(data.updates.data)
                setTotalPages(data.totalPages);
                setLoading(false)
            }
        })
    }, []);
    let getNextPageData = (e : any) => {
        let page = e.selected ?? 0;
        leaderboardService.teamMates(page+1).then(({data}:any) => {
            if (data.status_code == 200){
                setSocialUpdates(data.updates.data)
                setTotalPages(data.totalPages);
            }
        })
    }
  return (
    <Layout title="Teammates">
        {loading? <TeamMatesLoader/> : <div className="rt-content rt-teammates">
      {socialUpdates.map((d: any) => (
        <div className="rt-teamcard">
            <Link to={'member-profile/'+d.user.id}>
                <div className="rt-themecardbox rt-teamhead">
                    <figure className="rt-roundimage">
                        <img src={ASSET_ENDPOINT+'images/'+d.user.avatar} alt="" />
                    </figure>
                    <div className="rt-themecontent">
                        <h4>{d.user.full_name}</h4>
                        <span>{d.user.address}</span>
                        <em>{d.created}</em>
                    </div>
                </div>
                <div className="rt-teamcontent">
                    <figure className="rt-bannerimg">
                        <img src={ASSET_ENDPOINT_LIVE+'images/sessions/'+d.image} alt="" />
                        <figcaption className="rt-bannercontent">
                            <h2><i className="icon-clock"></i>{d.session_name}</h2>
                        </figcaption>
                    </figure>
                    <div className="rt-counterbox">
                        <div className="rt-count">
                            <h3>{d.touches}</h3>
                            <span>TOUCHES</span>
                        </div>
                        <div className="rt-count">
                            <h3>{d.shots}</h3>
                            <span>SHOTS</span>
                        </div>
                        <div className="rt-count">
                            <h3>{d.passes}</h3>
                            <span>PASSES</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
      ))}
          {totalPages > 1 ?
              <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'page-item'}
                  nextClassName={'rt-next page-item'}
                  previousClassName={'rt-prev page-item'}
                  pageCount={totalPages}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  previousLinkClassName={'page-link'}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e:any)=> getNextPageData(e)}
                  containerClassName={'rt-pagination pagination'}
                  activeClassName={'active'}
              /> : ''}
      </div>}

    </Layout>
  );
};
