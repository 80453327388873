import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstants'
import { SignupModal } from '../../../components';
import './login.scss';
import { Button } from 'react-bootstrap';
import {authService} from "../../../services/auth.service";
import {HttpService} from "../../../services/base.service";

export const LoginPage = () => {

    const [modalShow, setModalShow] = useState(false);
    const [email, setEmail] = useState('sfkazmi0@gmail.com');
    const [password, setPassword] = useState('123456');
    const [error, setError] = useState('');
    let history = useHistory();

    useEffect(() => {
        if (sessionStorage.getItem('success') != undefined){
            alert(sessionStorage.getItem('success'))
        }
    }, []);


    let login = async () => {
        await authService.login({email: email,password: password}).then(async ({data}) => {
            if (data.status_code = 200){
                await authService.authenticate({token: data.data.token,user: data.data.user})
                HttpService.setToken(data.data.token);
                await history.push(routes.dashboard)
            }
            if (data.status_code == 409){
                setError(data.message)
            }
        }).catch( error => {
            let res = error.response.data;
            if (res.status_code  == 301){
                setError(res.message)
            }
        });
    }
    
    return (
        <div className="rt-loginholder">
            <div className="rt-split rt-leftsite">
                <figure className="rt-signupimg"><img src={images.signupimg} alt="" /></figure>
            </div>
            <div className="rt-split rt-rightsite">
                <div className="rt-flexcontainer">
                    <div className="rt-formbox">
                        <form className="rt-formtheme rt-loginform">
                            <fieldset>
                                <div className="rt-logobox">
                                    <strong className="rt-signuplogo"><Link to={routes.login}><img src={images.signuplogo} alt="" /></Link></strong>
                                </div>
                                <div className="form-group">
                                    <h2><span>WELCOME</span>SIGN IN</h2>
                                </div>
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="email" name="email" className="form-control" id="email-field" placeholder="EMAIL ADDRESS" onChange={e => setEmail(e.target.value)} value={email} />
                                        <label htmlFor="email-field">EMAIL ADDRESS</label>
                                    </div>
                                    {/* <em className="rt-alertmessagetext"><i className="icon-error"></i>Please enter a valid email address</em> */}
                                </div>
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="password" name="password" className="form-control" id="password-field" placeholder="PASSWORD" onChange={e => setPassword(e.target.value)} value={password}/>
                                        <label htmlFor="password-field">PASSWORD</label>
                                    </div>
                                    {error != '' ? <em className="rt-alertmessagetext"><i className="icon-error"></i>{error}</em> : ''}
                                </div>
                                <div className="form-group">
                                    <Link className="rt-btnforgotpassword" to={routes.forgot}>FORGOT PASSWORD?</Link>
                                </div>
                                <div className="form-group">
                                    <Button className="rt-btn rt-btn-lg p-0" onClick={login}>SIGN IN</Button>
                                </div>
                                <div className="form-group text-center rt-signinwithtextbox">
                                    <span className="rt-signinwithtext">OR SIGN IN WITH</span>
                                </div>
                                <div className="form-group m-0">
                                    <Link className="rt-btn rt-btn-lg rt-btnbgwhisper rt-btnsigninfacebook p-0" to={routes.login}>
                                        <i className="icon-facebook"></i>
                                        <span>SIGN IN WITH FACEBOOK</span>
                                    </Link>
                                </div>
                                <div className="form-group rt-signuplogintextbox text-center">
                                    <span> DON’T HAVE AN ACCOUNT? <button type="button" className="button" onClick={() => setModalShow(true)}>SIGN UP</button></span>
                                    { modalShow && <SignupModal show={modalShow} onHide={() => setModalShow(false)} />}
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};