import React, { Component, useEffect } from 'react';
import { routes } from '../../../routes/routesConstants'
import { Link } from 'react-router-dom';
import './signup.scss';

export const SignupClub = () => {
    return (
        <div className="rt-signupholder">
            <div className="rt-formbox">
                <form className="rt-formtheme rt-forgotpasswordform">
                    <fieldset>
                        {/* <div className="rt-logobox">
                            <strong className="rt-signuplogo"><Link to={routes.login}><img src={images.signuplogo} alt="" /></Link></strong>
                        </div> */}
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                                <div className="form-group text-center"> 
                                    <h3>ACCOUNT INFORMATION</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group"> 
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" id="name-field" placeholder="CLUB NAME" />
                                        <label htmlFor="name-field">CLUB NAME</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" id="coachid-field" placeholder="Club Coach Email ID" />
                                        <label htmlFor="coachid-field">Club Coach Email ID</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" id="coachname-field" placeholder="Club Coach Name" />
                                        <label htmlFor="coachname-field">Club Coach Name</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" id="members-field" placeholder="Total Club Members" />
                                        <label htmlFor="members-field">Total Club Members</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="number" name="" className="form-control" id="phone-field" placeholder="PHONE" />
                                        <label htmlFor="phone-field">PHONE</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="number" name="" className="form-control" id="subscription-field" placeholder="Subscription Months" />
                                        <label htmlFor="subscription-field">Subscription Months</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" id="address-field" placeholder="Address" />
                                        <label htmlFor="address-field">Address</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group text-center">
                                    <span className="rt-alreadyaccount">ALREADY HAVE AN ACCOUNT? <Link to={routes.login}>LOGIN</Link></span>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group m-0">
                                    <Link className="rt-btn rt-btn-lg p-0" to={routes.login}>SUBMIT</Link>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};