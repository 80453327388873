import React, {useState} from "react";
import { Modal } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { images } from '../../assets/image'


export const ShareGoalsModal = (props:any) => {

    const [show, setShow] = useState(props.show);
    const handleClose = () => setShow(false);

    return(
        <Modal show={show} className="rt-thememodal rt-goalsmodal rt-sharegoalsmodal" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="rt-thememodalbox rt-goalsmodalbox">
                    <button className="rt-close" onClick={props.onHide}><i className="icon-cross"></i></button>
                    <div className="rt-goalscontent">
                        <div className="rt-modaltitle">
                            <h3>SHARE SCREENSHOT</h3>
                        </div>
                        <form className="rt-formtheme">
                            <fieldset>
                                <h4>I JUST COMPLETED <span> {props.session.name}</span> <br/> ON THE BEAST MODE SOCCER+ APP! </h4>
                                <ul className="rt-goalinfolist">
                                    <li>
                                        <div className="rt-goalstatbox">
                                            <em>{props.stats.touches}</em>
                                            <span>TOUCHES</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="rt-goalstatbox">
                                            <em>{props.stats.passes}</em>
                                            <span>PASSES</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="rt-goalstatbox">
                                            <em>{props.stats.shots}</em>
                                            <span>SHOTS</span>
                                        </div>
                                    </li>
                                </ul>
                                <div className="rt-description">
                                    <p>INSPIRE YOUR FRIENDS, AND FLEX A LITTLE!<br/> HIT THE BUTTON BELOW</p>
                                </div>
                                <div className="rt-shareinput">
                                    <input type="text" className="form-control" value="https://www.soccer.com" readOnly/>
                                    <button type="button" className="rt-sharebtn"><i className="icon-link"></i></button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
