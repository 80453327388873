import React from 'react';
import logo from './logo.svg'
import './App.scss';
import MainRoutes from './routes/mainRoutes';

function App() {
  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
