import React, {FormEvent, useState} from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../assets/image';
import { routes } from '../../routes/routesConstants';
import { render } from 'react-dom'
import { Layout } from '../../components'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import './settings.scss';
import {userProfileService} from "../../services/profile.service";

export const SettingsPage = () => {

    const [passwordData, setPasswordData] = useState({
        current_password: '',
        new_password:'',
        confirmNewPassword : '',
    });
    const [error, setError] = useState('');

    let updatePassword = (e : FormEvent) => {
        e.preventDefault();
        if (passwordData.new_password !== passwordData.confirmNewPassword || passwordData.new_password == ''){
            setError('Password and confirm password don\'t match');
            return 0;
        }

        userProfileService.updatePassword(passwordData).then(({data} : any) => {
            if (data.status_code == 200){
                alert('password updated successfully')
                setError('')
            }else{
                setError(data.message)
            }
        })

    }
    return (
        <Layout>
            <div className="rt-content rt-settings">
                <div className="rt-pageheading">
                    <div className="rt-title">
                        <Link to="/profile" className="rt-backbtn"><i className="icon-arrow-left"></i></Link>
                        <h2>settings</h2>
                    </div>
                </div>
                <div className="rt-settingsarea">
                    <div className="rt-formtheme rt-settingsform">
                        <fieldset>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav className="rt-navtabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">CHANGE PASSWORD</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">SUBSCRIPTION</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <form onSubmit={updatePassword}>
                                            <div className="rt-changepassword">
                                                <div className="form-group">
                                                    <div className="rt-fieldholder">
                                                        <input type="password" name="" className="form-control" id="currentpas-field" placeholder="CURRENT PASSWORD" value={passwordData.current_password} onChange={e => setPasswordData({...passwordData,current_password: e.target.value})}/>
                                                        <label htmlFor="currentpas-field">CURRENT PASSWORD</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="rt-fieldholder">
                                                        <input type="password" name="" className="form-control" id="newpas-field" placeholder="NEW PASSWORD" value={passwordData.new_password} onChange={e => setPasswordData({...passwordData,new_password: e.target.value})}/>
                                                        <label htmlFor="newpas-field">NEW PASSWORD</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="rt-fieldholder">
                                                        <input type="password" name="" className="form-control" id="confirmpas-field" placeholder="CONFIRM PASSWORD" value={passwordData.confirmNewPassword} onChange={e => setPasswordData({...passwordData,confirmNewPassword: e.target.value})}/>
                                                        <label htmlFor="confirmpas-field">CONFIRM PASSWORD</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    {error != '' ? <em className="rt-alertmessagetext"><i className="icon-error"></i>{error}</em> : ''}
                                                    <button type="submit" className="rt-btn rt-black-btn rt-btn-lg">CHANGE PASSWORD</button>
                                                </div>
                                            </div>
                                        </form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="rt-subscription">
                                            <div className="form-group">
                                                <div className="rt-customradiobox">
                                                    <label className="rt-customradiobtn">
                                                        <input type="radio" name="radio" />
                                                        <span>MONTHLY SUBSCRIPTION / RS 190.00</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="rt-customradiobox">
                                                    <label className="rt-customradiobtn">
                                                        <input type="radio" name="radio" />
                                                        <span>YEARLY SUBSCRIPTION / RS 1,569,66</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="button" className="rt-btn rt-black-btn rt-btn-lg">UPDATE SUBSCRIPTION</button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </fieldset>
                    </div>
                </div>
            </div>
        </Layout>
    );
};