class APIEndpoints {
    LOGIN = 'logout/from/others';
    SIGNUP = 'sign-up';
    FORGET_PASSWORD = 'forget/password';
    PASSWORD_UPDATE = 'forget/password/update';
    DASHBOARD = 'v2/web/dashboard';
    DELETE_GOAL = 'note/delete';
    LEADERBOARD_MONTHLY = 'user/leaderboard/monthly';
    LEADERBOARD_ALL = 'user/leaderboard';
    ADD_GOAL = 'note/add';
    FIND_MEMBERS = 'find/members';
    FOLLOWS = 'my/follows';
    COUNTRIES = 'get/states';
    UPDATE_PROFILE = 'update/profile';
    UPDATE_PASSWORD = 'update/password';
    SESSION_CATEGORIES = 'session/categories';
    BMSU_CATEGORIES = 'bmsu/categories';
    SUBMIT_STATS = 'user/session/submit/stats';
    CHALLENGES = 'v2/user/challanges';
    SOCIAL_UPDATES = 'user/social/update';
    FOLLOW = 'follow/user';
    UN_FOLLOW = 'unfollow/user';
    ORGANIZE_TRAINING_INDEX = 'organize/training';
    ADD_TRAINING_SESSION = 'add/session';
    DELETE_TRAINING_SESSION = 'today/session/delete';
}

export default new APIEndpoints();